import qs from "query-string";
const jwt = require('jsonwebtoken');

export const DollarFormatter = (value) => {
  return `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const DollarParser = (value) => {
  return value.replace(/\$\s?|(,*)/g, '')
}

export const updateUrlQueryString = (newValues, search, history) => {
  const values = qs.parse(search);
  const newQsValue = qs.stringify({
    ...values,
    ...newValues,
  });
  history.push({
    search: newQsValue,
    pathname: history.location.pathname,
  });
}

export const IncrementSection = (s) => {
  // first case - string is empty: return "a"
  if (!s || s.length === 0) {
    return "A";
  }

  // last char is less than 'z': simply increment last char
  let lastChar = s[s.length - 1];
  let fragment = s.substring(0, s.length - 1);
  if (lastChar < 'Z') {
    lastChar = String.fromCharCode(lastChar.charCodeAt(0) + 1);
    return fragment + lastChar;
  }
  // last char is 'z': roll over and increment preceding string
  return IncrementSection(fragment) + 'A';
}

export const IncrementLot = (s, incrementLotBy) => {
  if (!s || s.length === 0) {
    return 1;
  }

  let lot = parseInt(s);
  lot += incrementLotBy;
  return lot;
}

export const HardReloadFrontend = () => {
  navigator.serviceWorker.getRegistrations().then((registrations) => {
    registrations.forEach((registration) => {
      registration.unregister();
    });
  });
  caches.keys().then((keyList) => {
    return Promise.all(
      keyList.map((key) => {
        return caches.delete(key);
      })
    );
  });
  setTimeout(() => {
    window.location.reload();
  }, 900);
}

export const CapitalizeEachArrayElement = (array) => {
  return array.map((item) => {
    return item.charAt(0).toUpperCase() + item.slice(1);
  });
}

export const CheckIfGlobalAdmin = (jwtToken) => {
  const decodedToken = jwt.decode(jwtToken, { complete: true });
  const payload = decodedToken.payload;

  return payload.user_type === "a";
}