import React from "react";
import {
  ApartmentOutlined,
  CarOutlined,
  CodeSandboxOutlined,
  ControlOutlined,
  DashboardOutlined,
  DatabaseOutlined,
  DownloadOutlined,
  EditOutlined,
  FileAddOutlined,
  FileTextOutlined,
  FileZipOutlined,
  GroupOutlined,
  HomeOutlined,
  InboxOutlined,
  PercentageOutlined,
  ReconciliationOutlined,
  SettingOutlined,
  ShopOutlined,
  ShoppingCartOutlined,
  SnippetsOutlined,
} from "@ant-design/icons";

import NotFoundPage from "./pages/404Page";

import DashboardLayout from "./layouts/dashboard";
import Login from "./pages/login";

const HomePageComponent = React.lazy(() =>
  import("./pages/homePage")
);
const AccountsPageComponent = React.lazy(() =>
  import("./pages/accounts")
);
const UsersPageComponent = React.lazy(() =>
  import("./pages/users")
);
const InternalLogsPageComponent = React.lazy(() =>
  import("./pages/internalLogs")
);
const SettingsPageComponent = React.lazy(() =>
  import("./pages/settings")
);

export const INDEX_ROUTE = "/";
export const ACCOUNTS_ROUTE = "/accounts";
export const USERS_ROUTE = "/accounts/:accountId/users";
export const INTERNAL_LOGS_ROUTE = "/internal-logs";
export const SETTINGS_ROUTE = "/settings";

export const NON_LOGIN_ROUTES = [
  {
    exact: true,
    name: "login",
    path: INDEX_ROUTE,
    component: Login,
  },
  {
    component: NotFoundPage,
  },
];

export const LOGGED_IN_ROUTES = [
  {
    name: "layout",
    path: INDEX_ROUTE,
    component: DashboardLayout,
    routes: [
      {
        exact: true,
        name: "home",
        path: INDEX_ROUTE,
        component: HomePageComponent,
      },
      {
        exact: true,
        name: "accounts",
        path: ACCOUNTS_ROUTE,
        component: AccountsPageComponent,
      },
      {
        exact: true,
        name: "users",
        path: USERS_ROUTE,
        component: UsersPageComponent,
      },
      {
        exact: true,
        name: "internal-logs",
        path: INTERNAL_LOGS_ROUTE,
        component: InternalLogsPageComponent,
      },
      {
        exact: true,
        name: "settings",
        path: SETTINGS_ROUTE,
        component: SettingsPageComponent,
      },
    ],
  },
  {
    component: NotFoundPage,
  },
];

export const SIDEBAR_ITEMS = [
  {
    name: "home",
    label: "Dashboard",
    route: INDEX_ROUTE,
    icon: <DashboardOutlined />,
  },
  {
    name: "accounts",
    label: "Accounts",
    route: ACCOUNTS_ROUTE,
    icon: <GroupOutlined />,
  },
  {
    name: "settings",
    label: "Settings",
    route: SETTINGS_ROUTE,
    icon: <SettingOutlined />,
  },
  {
    name: "internal-logs",
    label: "Internal Logs",
    route: INTERNAL_LOGS_ROUTE,
    icon: <ReconciliationOutlined />,
  },
];
