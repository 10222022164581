import { message } from "antd";
import loggerServiceInstance from "../utilities/loggerService";
import * as Sentry from "@sentry/react";

const isProduction = true;  //process.env.NODE_ENV === "production";
const defaultErrorMessage = "Unknown error occurred. Support has been notified.";

class ResponseErrorHandlingService {

    handle(error) {
        const errorDisplay = error?.response?.data?.detail || error?.response?.data?.message || error?.message;

        // check if the error is a string
        if (typeof errorDisplay !== "string") {
            if (isProduction) {
                Sentry.captureException(defaultErrorMessage);
            }
            message.error(defaultErrorMessage);
            return;
        }

        loggerServiceInstance.log(errorDisplay);
        message.error(errorDisplay);
        if (isProduction) {
            Sentry.captureException(error);
        }
    }
}

const responseErrorService = new ResponseErrorHandlingService();
export default responseErrorService;

// how to use:
// import responseErrorService from "../utilities/responseErrorHandling";
// responseErrorService.log("hello world");